<template>
  <form
    class="form-cms"
    @submit.prevent="updateRecipeStep({ id, title, order, description })"
    :class="[isShowMsgStep ? 'mb-1' : '']"
  >
    <div class="fields-flex mt-2">
      <div class="form-control">
        <label for="order-step-recipe">Orden:</label>
        <input
          type="number"
          name="order-recipe"
          placeholder="Orden"
          v-model="order"
        />
      </div>
      <div class="form-control">
        <label for="title-step-recipe">Título:</label>
        <input
          type="text"
          name="title-step-recipe"
          placeholder="Título"
          v-model="title"
        />
      </div>
    </div>
    <div class="form-control">
      <label for="description-step-recipe">Descripción:</label>

      <textarea
        rows="6"
        name="description-step-recipe"
        placeholder="Descripción"
        v-model="description"
      />
    </div>
    <Message
      v-if="isShowMsgStep"
      :message="stepMsg"
      :messageType="stepMsgStatus"
    />
    <div class="buttons mt-1 gap-0-5">
      <button
        class="btn btn-primary btn-medium"
        type="submit"
        :disabled="isLoadingUpdate"
      >
        <template v-if="!isLoadingUpdate"
          ><i class="fas fa-pen-alt"></i> Actualizar
        </template>
        <template v-else>Actualizando ...</template>
      </button>
      <button
        type="button"
        @click="deleteRecipeStep(id)"
        :disabled="isLoadingDelete"
        class="btn btn-secondary btn-medium"
      >
        <template v-if="!isLoadingDelete">
          <i class="far fa-trash-alt"></i> Eliminar
        </template>
        <template v-else>Eliminando ...</template>
      </button>
    </div>
  </form>
</template>

<script>
import { defineAsyncComponent } from 'vue';
import useRecipeSteps from '@/modules/cms/composables/recipes/useRecipeSteps';

export default {
  props: {
    id: {
      type: Number,
      required: true,
    },
    order: {
      type: Number,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
  },
  components: {
    Message: defineAsyncComponent(() =>
      import(/* webpackChunkName: "Message" */ '@/components/Message.vue')
    ),
  },
  setup() {
    const {
      isLoadingUpdate,
      updateRecipeStep,
      stepMsg,
      isShowMsgStep,
      stepMsgStatus,

      deleteRecipeStep,
      isLoadingDelete,
    } = useRecipeSteps();

    return {
      isLoadingUpdate,
      updateRecipeStep,
      stepMsg,
      isShowMsgStep,
      stepMsgStatus,

      deleteRecipeStep,
      isLoadingDelete,
    };
  },
};
</script>
